import React from 'react';
import MainFrame from "../main/MainFrame";
import AboutTopMiddle from "../about/AboutTopMiddle";

function About() {

    return (
        <MainFrame
            heading={<>andrew<br/>brown</>}
            topLeftContent={ <></> }
            topMiddleContent={ <AboutTopMiddle />}
            topRightContent={ <></> }
            bottomHeading={ "MORE ABOUT ME" }
            bottomContent={ <></> }
        />
    )
}

export default About;