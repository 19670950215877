import React from 'react';
import MainFrame from "../main/MainFrame";
import ProjectsPanel from "../home/ProjectsPanel";
import HomeTopMiddle from "../home/HomeTopMiddle";
import HomeTopLeft from "../home/HomeTopLeft";
import HomeTopRight from "../home/HomeTopRight";


function Home() {

    return (
        <MainFrame
            topLeftContent={ <HomeTopLeft /> }
            topMiddleContent={ <HomeTopMiddle /> }
            topRightContent={ <HomeTopRight /> }
            bottomHeading={ "CHECK ME OUT" }
            bottomContent={ <ProjectsPanel/> }
        />
    )
}

export default Home;