import React from 'react';
import styles from '../styles/about.module.css'
import {motion} from "framer-motion"
const AboutTopMiddle = () => {
    return (
        <motion.div>
        </motion.div>
    );
};

export default AboutTopMiddle;